import React, { useState } from "react";
import { Caida, Nazi, Puigde, Mundial } from "../images";
import { useNavigate } from "react-router-dom";
import DisplayText from "./DisplayText";

const data = {
  question:
    "Escoge la imagen que mejor describe el sucesos ocurrido el 27 de octubre de 2017:",
  images: [Nazi, Puigde, Caida, Mundial],
  answer: 2,
};

function Fotos() {
  const [isChangingColor, setIsChangingColor] = useState(false);
  const [buttonStates, setButtonStates] = useState(Array(4).fill(0));
  const [isDisplayText, setIsDisplayText] = useState(false);
  const navigate = useNavigate();

  function checkAnswer(clickedIndex) {
    return data.answer === clickedIndex;
  }

  function isClicked() {
    navigate("/video");
  }

  const handleMouseUp = (index) => {
    const answer = checkAnswer(index);
    let answers_array = Array(4).fill(0);
    answers_array[index] = answer ? 2 : 1;
    setButtonStates(answers_array);
    setIsChangingColor(true);

    setTimeout(() => {
      setIsChangingColor(false);
      setButtonStates(Array(4).fill(0));
      if (answer) setIsDisplayText(true);
    }, 500);
  };

  return isDisplayText ? (
    <DisplayText text={2} isClicked={isClicked} />
  ) : (
    <div class="mx-16 grid h-full grid-cols-1 grid-rows-8 items-center justify-center gap-y-8 py-8">
      <div class="flex w-full justify-center rounded-lg border-2 border-black bg-white bg-opacity-40 p-6 shadow-xl">
        <label class="text-4xl font-bold text-gray-800">{data.question}</label>
      </div>

      <div class="row-span-7 flex h-full w-full rounded-lg border-2 border-black bg-white bg-opacity-40 shadow-xl ">
        <div class="m-8 grid w-full grid-cols-2 gap-6 text-xl font-bold">
          {data.images.map((image, index) => (
            <button
              key={index}
              class={`rounded-lg ${
                buttonStates[index] === 0
                  ? " border-2 border-stone-700 hover:border-purple-200"
                  : buttonStates[index] === 1
                    ? "border-2 border-black border-red-600"
                    : "border-2 border-black border-green-600"
              }`}
              onMouseUp={() => handleMouseUp(index)}
              disabled={isChangingColor}
              style={{ position: "relative" }}
            >
              <img
                class="absolute inset-0 h-full w-full rounded-lg object-fill"
                src={image}
                alt={`Image ${index}`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Fotos;
