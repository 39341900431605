import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DisplayText from "./DisplayText";

const questions = [
  {
    question: "Eres ingeniero si...",
    options: [
      "Llevas siempre encima 2 bolis como mínimo (o uno de 4 colores)",
      "Eres cabezón nivel: reformar una cocina con un pie roto",
      "Lo arreglas siempre todo todo y todo",
      "Todas son correctas",
    ],
    answer: 3,
  },
  {
    question: "¿Cómo comen los lobos?",
    options: [
      "Muy rápido y devorando",
      "Compartiendo",
      "Sin pan",
      "Con cuchillo y tenedor",
    ],
    answer: 2,
  },
  {
    question: "¿Qué hay que hacer con un ingeniero para ahorrarnos tiempo?",
    options: [
      "Darle siempre la razón",
      "Pagarle por adelantado",
      "Estudiar antes de quedar con él",
      "Ignorarlo",
    ],
    answer: 0,
  },
];

function TipoTest() {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isChangingColor, setIsChangingColor] = useState(false);
  const [buttonStates, setButtonStates] = useState(Array(4).fill(0));
  const [isDisplayText, setIsDisplayText] = useState(true);

  const navigate = useNavigate();
  function checkAnswer(clickedIndex) {
    return questions[questionIndex].answer === clickedIndex;
  }

  function isClicked() {
    setIsDisplayText(false);
  }

  const handleMouseUp = (index) => {
    const answer = checkAnswer(index);
    let answers_array = Array(4).fill(0);
    answers_array[index] = answer ? 2 : 1;
    setButtonStates(answers_array);
    setIsChangingColor(true);

    setTimeout(() => {
      setIsChangingColor(false);
      setButtonStates(Array(4).fill(0));
      if (answer) {
        if (questionIndex >= questions.length - 1) navigate("/fotos");
        else setQuestionIndex(questionIndex + 1);
      }
    }, 500);
  };

  return isDisplayText ? (
    <DisplayText text={1} isClicked={isClicked} />
  ) : (
    <div class="mx-16 grid h-full grid-cols-1 grid-rows-3 items-center justify-center ">
      <div class="flex  w-full justify-center rounded-lg border-2 border-black bg-white bg-opacity-40 p-8 shadow-xl">
        <label class="text-4xl font-bold text-gray-800">
          {questions[questionIndex].question}
        </label>
      </div>
      <div class="row-span-2 flex h-4/5 w-full rounded-lg border-2 border-black bg-white bg-opacity-40 shadow-xl">
        <div class="m-8 grid w-full grid-cols-2 gap-6 text-xl font-bold">
          {questions[questionIndex].options.map((answer, index) => (
            <button
              key={index}
              class={`rounded-lg ${
                buttonStates[index] === 0
                  ? "border border-stone-700 bg-red-100 hover:bg-purple-200"
                  : buttonStates[index] === 1
                    ? "border-2 border-black bg-red-600"
                    : "border-2 border-black bg-green-600"
              }`}
              onMouseUp={() => handleMouseUp(index)}
              disabled={isChangingColor}
            >
              {answer}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TipoTest;
