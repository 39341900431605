import React, { useState } from "react";

function Video() {
  const [youtubeID] = useState("rd4JYSvc6-o");

  return (
    <div class="row-span-7 flex h-full w-full rounded-lg border-2 border-black bg-white bg-opacity-40 shadow-xl ">
      <iframe
        className="h-full w-full"
        title="Youtube player"
        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
        src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}
      ></iframe>
    </div>
  );
}

export default Video;
