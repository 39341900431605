import { useNavigate } from "react-router-dom";
import TipoTest from "./TipoTest";

function DisplayText(props) {
  return (
    <div class="mx-16 grid h-full grid-cols-3 grid-rows-8 items-center justify-center gap-y-8 py-8">
      <div class="col-span-3 row-span-7 flex w-full whitespace-pre-line rounded-lg border-2 border-black bg-white bg-opacity-40 py-5 text-7xl leading-tight shadow-xl">
        <p>
          {props.text === 1
            ? "Para poder acceder a tu regalo, debes superar una mini prueba. \n Esta prueba consiste en responder correctamente las siguientes preguntas:"
            : "¡Has superado la prueba con éxito!\n Haz clic en 'continuar' para reproducir tu regalo."}
        </p>
      </div>

      <button
        onClick={props.isClicked}
        type="button"
        class="shadow-dark-3 hover:shadow-dark-2 focus:shadow-dark-2 active:shadow-dark-2 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong col-start-3 inline-block h-full rounded-full bg-neutral-600 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:bg-neutral-700 focus:bg-neutral-700 focus:outline-none focus:ring-0 active:bg-neutral-900 motion-reduce:transition-none dark:shadow-black/30"
      >
        Continuar
      </button>
    </div>
  );
}

export default DisplayText;
